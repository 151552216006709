import * as PopoverPrimitives from "@radix-ui/react-popover";
import clsx from "clsx";
import { forwardRef } from "react";
import "./faPopoverClose.scss";

type FaPopoverCloseProps = PopoverPrimitives.PopoverCloseProps;

export const FaPopoverClose = forwardRef<HTMLButtonElement, FaPopoverCloseProps>((props, forwardedRef) => {
    const { children, className, ...triggerProps } = props;
    return (
        <PopoverPrimitives.Close ref={forwardedRef} className={clsx("fa-popover-close", className)} {...triggerProps}>
            {children}
        </PopoverPrimitives.Close>
    );
});
