import { FaPopoverArrow } from "./FaPopoverArrow/FaPopoverArrow";
import { FaPopoverClose } from "./FaPopoverClose/FaPopoverClose";
import { FaPopoverContent } from "./FaPopoverContent/FaPopoverContent";
import { FaPopoverRoot } from "./FaPopoverRoot/FaPopoverRoot";
import { FaPopoverTrigger } from "./FaPopoverTrigger/FaPopoverTrigger";

export const FaPopover = FaPopoverRoot;
export const Root = FaPopoverRoot;

export const Trigger = FaPopoverTrigger;
export const Close = FaPopoverClose;
export const Content = FaPopoverContent;
export const Arrow = FaPopoverArrow;
