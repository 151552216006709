import * as PopoverPrimitives from "@radix-ui/react-popover";
import clsx from "clsx";
import { forwardRef } from "react";
import "./faPopoverContent.scss";

type FaPopoverContentProps = PopoverPrimitives.PopoverContentProps;

export const FaPopoverContent = forwardRef<HTMLDivElement, FaPopoverContentProps>((props, forwardedRef) => {
    const { children, sideOffset, collisionPadding, className, ...contentProps } = props;

    return (
        <PopoverPrimitives.Portal>
            <PopoverPrimitives.Content
                ref={forwardedRef}
                className={clsx("fa-popover-content", className)}
                sideOffset={sideOffset ?? 8}
                collisionPadding={collisionPadding ?? 16}
                {...contentProps}>
                {children}
            </PopoverPrimitives.Content>
        </PopoverPrimitives.Portal>
    );
});
